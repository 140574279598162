import { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';

import { Button, Container, Grid, Typography, TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { Api, User as UserType, UnauthorizedError } from '../Api';

const useStyles = makeStyles(() => ({
  button: { width: '100%' },
}));

function User(props: any) {
  const [state, setState] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    zip: '',
    city: '',
    street: '',
    phone: '',
  } as UserType);

  const styles = useStyles();

  useEffect(() => {
    async function updateUser() {
      const api = new Api();

      try {
        const user = await api.getCurrentUser();
        setState(user);
      } catch (e) {
        if (e instanceof UnauthorizedError) {
          props.history.push('/login');
        } else {
          console.error(`Unexpected error: ${e}`);
        }
      }
    }
    updateUser();
  }, [props.history]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const api = new Api();

    try {
      await api.updateUser(state.id, state);
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        props.history.push('/login');
      } else {
        console.error(`Unexpected error: ${e}`);
      }
    }
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          style={{
            padding: '50px 0px 50px 0px',
          }}
        >
          Allgemeine Einstellungen
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="Vorname"
                name="firstName"
                value={state.firstName}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Nachname"
                name="lastName"
                value={state.lastName}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="E-Mail-Adresse"
                name="email"
                value={state.email}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Postleitzahl"
                name="zip"
                value={state.zip}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Ort"
                name="city"
                value={state.city}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Straße und Hausnummer"
                name="street"
                value={state.street}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Telefon/Handynummer"
                name="phone"
                value={state.phone}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" className={styles.button} type="submit">
                Speichern
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default withRouter(User);
