export class UnauthorizedError extends Error {}

export class HttpError extends Error {
  constructor(private code: number) {
    super(`${code}`);
  }
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  zip: string;
  city: string;
}

const BACKEND = 'https://alpha.my-court.de';
// const BACKEND = 'http://localhost:8080';

export class Api {
  public async login(email: string, password: string): Promise<void> {
    const response = await fetch(`${BACKEND}/api/session`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    switch (response.status) {
      case 200:
        return;
      case 401:
        throw new UnauthorizedError();
      default:
        throw new HttpError(response.status);
    }
  }

  public async logout(): Promise<void> {
    const response = await fetch(`${BACKEND}/api/session`, {
      credentials: 'include',
      method: 'DELETE',
    });

    switch (response.status) {
      case 200:
        return;
      case 401:
        throw new UnauthorizedError();
      default:
        throw new HttpError(response.status);
    }
  }

  public async getCurrentUser(): Promise<User> {
    const response = await fetch(`${BACKEND}/api/users/me`, {
      credentials: 'include',
      method: 'GET',
    });

    switch (response.status) {
      case 200:
        return await response.json();
      case 401:
        throw new UnauthorizedError();
      default:
        throw new HttpError(response.status);
    }
  }

  public async updateUser(id: string, user: User): Promise<void> {
    const response = await fetch(`${BACKEND}/api/users/${id}`, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });

    switch (response.status) {
      case 200:
        return;
      case 401:
        throw new UnauthorizedError();
      default:
        throw new HttpError(response.status);
    }
  }
}
