import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from './logo_my_court.png';

const useStyles = makeStyles(() => ({
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '60px',
    'background-color': '#f5f5f5',
  },
  grid: {
    height: '100%',
  },
}));

function Footer() {
  const styles = useStyles();

  return (
    <footer className={styles.footer}>
      <Grid container justify="space-around" alignItems="center" className={styles.grid}>
        <Grid item>
          <Box color="text.disabled">©{new Date().getFullYear()} All rights reserved.</Box>
        </Grid>
        <Grid item>
          <a href="https://www.my-court.de" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Logo My Court" />
          </a>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
