import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container, Grid, Typography, TextField, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HttpsIcon from '@material-ui/icons/Https';
import Footer from './Footer';
import { Api, UnauthorizedError } from '../Api';

interface State {
  email: string;
  password: string;
}

const useStyles = makeStyles(() => ({
  button: { width: '100%' },
  ssl: { marginTop: 15 },
  sslDetail: { marginTop: 15 },
}));

function Registration(props: any) {
  const [state, setState] = React.useState({
    email: '',
    password: '',
  } as State);

  const [sslDetails, setSslDetails] = React.useState(false);

  const styles = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const api = new Api();
    const { email, password } = state;

    try {
      await api.login(email, password);
      props.history.push('/dashboard');
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        props.history.push('/login');
      } else {
        console.error(`Unexpected error: ${e}`);
      }
    }
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          style={{
            padding: '50px 0px 50px 0px',
          }}
        >
          Bitte einloggen
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="E-Mail-Adresse"
                name="email"
                value={state.email}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Passwort"
                name="password"
                value={state.password}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" className={styles.button} type="submit">
                Login
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                className={styles.button}
                component={RouterLink}
                to="/register"
              >
                Registrieren
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Container maxWidth="xs" className={styles.ssl}>
        <Grid container justify="center" alignItems="center">
          <Grid>
            <Chip
              label="Sicherer Server"
              icon={<HttpsIcon />}
              color="primary"
              variant="outlined"
              onClick={() => {
                setSslDetails(!sslDetails);
              }}
            />
          </Grid>
        </Grid>
        {sslDetails && (
          <Typography className={styles.sslDetail}>
            my court ist der Schutz Ihrer persönlichen Daten sehr wichtig. Daher wird der
            Branchenstandard SSL (Secure Sockets Layer) verwendet, um Ihre vertraulichen Daten bei
            der Übertragung über das Internet zu verschlüsseln.
          </Typography>
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default Registration;
