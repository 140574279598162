import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import Registration from './login/Registration';
import Login from './login/Login';
import Dashboard from './app/Dashboard';

function App() {
  return (
    <Router>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Registration} />
      <Route path="/dashboard" component={Dashboard} />
      <Route
        path="/dashboard"
        render={() => {
          return <Redirect to="/dashboard/home" />;
        }}
      />
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to="/login" />;
        }}
      />
    </Router>
  );
}

export default App;
