import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Footer from './Footer';

interface State {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  password2: string;
  zip: string;
  city: string;
  street: string;
  phone: string;
  terms: boolean;
}

const useStyles = makeStyles(() => ({
  button: { width: '100%' },
}));

function Registration() {
  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    zip: '',
    city: '',
    street: '',
    phone: '',
    terms: true,
  } as State);

  const styles = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target as HTMLInputElement;
    console.log({ name, checked });

    setState({
      ...state,
      [name]: checked,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await fetch('https://alpha.my-court.de/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      });

      console.log(`${response.status} ${response.statusText}`);
    } catch (e) {
      console.error(`Could not create user: ${e}`);
    }

    console.log(state);
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          style={{
            padding: '50px 0px 50px 0px',
          }}
        >
          Bitte registrieren Sie sich
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="Vorname"
                name="firstName"
                value={state.firstName}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Nachname"
                name="lastName"
                value={state.lastName}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="E-Mail-Adresse"
                name="email"
                value={state.email}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Passwort"
                name="password"
                value={state.password}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Passwort"
                name="password2"
                value={state.password2}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Postleitzahl"
                name="zip"
                value={state.zip}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Ort"
                name="city"
                value={state.city}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Straße und Hausnummer"
                name="street"
                value={state.street}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Telefon/Handynummer"
                name="phone"
                value={state.phone}
                onChange={handleChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                style={{ width: '100%' }}
                control={
                  <Checkbox
                    name="terms"
                    value={state.terms}
                    onChange={handleCheckBox}
                    color="primary"
                  />
                }
                label={
                  <div>
                    <span>Ich akzeptiere die </span>
                    <a
                      href="Datenschutzerklaerung.pdf"
                      color="secondary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography color="primary" component="span">
                        allgemeinen Geschäftsbedingungen
                      </Typography>
                    </a>
                  </div>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" className={styles.button} type="submit">
                Registrieren
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                className={styles.button}
                component={RouterLink}
                to="/login"
              >
                Zurück zum Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Footer />
    </div>
  );
}

export default Registration;
