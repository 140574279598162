import { useEffect, useState } from 'react';

import { Switch, Route, NavLink as RouterLink, useRouteMatch } from 'react-router-dom';

import {
  AppBar,
  Button,
  CssBaseline,
  Drawer,
  Divider,
  Grid,
  Hidden,
  Theme,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  createStyles,
  useTheme,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';

import { Api, UnauthorizedError, User } from '../Api';
import UserComponent from './User';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeLink: {
      backgroundColor: theme.palette.text.disabled,
    },
  })
);

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  activeClassName: string;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, activeClassName } = props;

  return (
    <ListItem button component={RouterLink} to={to} activeClassName={activeClassName}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
}

function Dashboard(props: any) {
  let match = useRouteMatch();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [user, setUser] = useState({} as User);

  async function logout(): Promise<void> {
    const api = new Api();

    try {
      await api.logout();
    } catch (e) {
      console.error(`Could not logout: ${e}`);
    }

    props.history.push('/login');
  }

  useEffect(() => {
    async function updateUser() {
      const api = new Api();

      try {
        const user = await api.getCurrentUser();
        setUser(user);
      } catch (e) {
        if (e instanceof UnauthorizedError) {
          props.history.push('/login');
        } else {
          console.error(`Unexpected error: ${e}`);
        }
      }
    }
    updateUser();
  }, [props.history]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItemLink
          to={`${match.url}/home`}
          primary="Home"
          icon={<HomeIcon />}
          activeClassName={classes.activeLink}
        />
        <ListItemLink
          to={`${match.url}/user`}
          primary="Einstellungen"
          icon={<SettingsIcon />}
          activeClassName={classes.activeLink}
        />
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h6" noWrap>
                Hallo {user.firstName} {user.lastName}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={logout}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path={`${match.path}/home`}>{JSON.stringify(user, null, 2)}</Route>
          <Route path={`${match.path}/user`}>
            <UserComponent />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export default Dashboard;
